import * as XLSX from 'xlsx';
import { AxiosResponse } from 'axios';

import { CategoryStatus, CategoryStatusSpanish } from './enums';
import { dateFormat } from './momentInstance';
import { FileUrl } from 'src/app/feature/Common/models/GeneralResponse';

export const truncateString = (
  str: string,
  num: number,
  ellipsis: boolean = true
) => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  } else {
    if (!ellipsis) {
      return str.slice(0, num);
    } else {
      // Return str truncated with '...' concatenated to the end of str.
      return str.slice(0, num) + '...';
    }
  }
};

interface FinancialFormatProps {
  amount: number;
  format?: string;
  style?: 'decimal' | 'currency' | 'percent';
  currency?: string;
  maximumFractionDigits?: number;
}

export const financialFormat = ({
  amount,
  format = 'es-CR',
  style = 'currency',
  currency = 'CRC',
  maximumFractionDigits = 2
}: FinancialFormatProps) => {
  return new Intl.NumberFormat(format, {
    style,
    currency,
    maximumFractionDigits
  }).format(amount ?? 0);
};

export const monthsArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const sortMonthsArray = (array: any[]) => {
  //!NOTA: Para usar esta función se requiere que el array tenga como propiedad en cada objeto "month", que sea de 12 elementos y que esten en ingles los meses
  const newArray = array?.sort((a, b) => {
    return monthsArray.indexOf(a.month) - monthsArray.indexOf(b.month);
  });
  return newArray;
};

export const suprimeDuplicateElementsArray = (array: any[]) => {
  let newArray = array?.filter((item, index) => {
    return (
      index ===
      array.findIndex((obj) => {
        return JSON.stringify(obj) === JSON.stringify(item);
      })
    );
  });
  return newArray;
};

export const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const tieneInterseccion = (array1: any[], array2: any[]) => {
  for (const elemento of array1) {
    if (array2.includes(elemento)) {
      return true;
    }
  }
  for (const elemento of array2) {
    if (array1.includes(elemento)) {
      return true;
    }
  }
  return false;
};

export const getFee = (price: number, porcent: number) => {
  const aux = porcent / 100;
  return porcent > 0 ? price * aux : 0;
};

export const roundNumber = (value: number, decimals = 3) => {
  return Number(value?.toFixed(decimals));
};

export const validateInitWith0 = (value: string) => {
  //* Aunque se le el valor que recive es un string, esta funcion es para validar inputs que trabajen con numeros, valida si la cantidad pasada empieza con un string '0100' ---> '100'
  if (value[0] === '0') {
    return value.substring(1);
  } else {
    return value;
  }
};

export const containLetters = (input: string | number) => {
  const val = String(input);
  const urlRegex = new RegExp(/[a-zA-Z]/);
  return urlRegex.test(val);
};

export const containSpecialCharacterExceptDotAndComma = (
  input: string | number
) => {
  const val = String(input);
  const urlRegex = new RegExp(/[^\w\s.,]/);
  return urlRegex.test(val);
};

export const validateInputAmount = (input: string | number) => {
  const text = String(input);
  let value = text?.replace(',', '');
  const valueArray = value?.split('');
  const aux: string[] = [];
  valueArray?.forEach((e) => {
    if (!containLetters(e) && !containSpecialCharacterExceptDotAndComma(e)) {
      aux.push(e);
    }
  });
  value = aux?.join('');
  if (value[value.length - 1] === '.' || value[value.length - 1] === ',') {
    value = value.slice(0, -1);
  }
  return validateInitWith0(value);
};

export const formatNumber = (num: number | string) => {
  // Elimina cualquier carácter que no sea un dígito o un punto decimal
  const cleaned = ('' + num).replace(/[^\d.]/g, '');

  // Divide el número en parte entera y decimal
  const [integerPart, decimalPart] = cleaned.split('.');

  // Formatea la parte entera con comas como separadores de miles
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Si hay una parte decimal, la agrega al número formateado
  const formatted =
    decimalPart !== undefined
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;

  return validateInitWith0(formatted);
};

export const translateStatus = (status: string) =>
  status.toUpperCase() === CategoryStatus.ACTIVE.toUpperCase()
    ? CategoryStatusSpanish.ACTIVE.toUpperCase()
    : CategoryStatusSpanish.INACTIVE.toUpperCase();

export const convertMilitaryToStandard = (time: string): string => {
  // Dividir la cadena en horas y minutos
  const [hours, minutes] = time.split(':').map(Number);

  // Determinar si es AM o PM
  const period = hours >= 12 ? 'pm' : 'am';

  // Convertir las horas de formato 24 horas a formato 12 horas
  let standardHours = hours % 12 || 12;

  // Formatear la hora en el nuevo formato, agregando un cero si es necesario
  const formattedHours =
    standardHours < 10 ? `0${standardHours}` : standardHours.toString();
  const formattedTime = `${formattedHours}:${minutes
    .toString()
    .padStart(2, '0')}${period}`;

  return formattedTime;
};

export const convertStandardToMilitary = (time: string): string => {
  // Extraer el periodo (am/pm) de la cadena
  const period = time.slice(-2).toLowerCase();

  // Dividir la cadena en horas y minutos
  const [hours, minutes] = time.slice(0, -2).split(':').map(Number);

  // Convertir las horas de formato 12 horas a formato 24 horas
  let militaryHours = period === 'pm' && hours !== 12 ? hours + 12 : hours;
  if (period === 'am' && hours === 12) {
    militaryHours = 0;
  }

  // Formatear la hora en el nuevo formato, agregando un cero si es necesario
  const formattedHours = militaryHours.toString().padStart(2, '0');
  const formattedTime = `${formattedHours}:${minutes
    .toString()
    .padStart(2, '0')}`;

  return formattedTime;
};

export const replaceZDate = (date: string) => {
  return date?.replace('Z', '');
};

export const dateFormatReplaceZDate = (date: any, format?: string) => {
  return dateFormat(replaceZDate(date), format);
};

export const recoveryDate = (date: string, time: string) => {
  let militaryTime = time;
  if (time?.includes('m')) {
    militaryTime = convertStandardToMilitary(time);
  }
  return `${dateFormat(replaceZDate(date))}T${militaryTime}`;
};

export const capitalize = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const getFirstDayOfWeek = () => {
  const firstDay = new Date();
  const dayOfWeek = firstDay.getDay(); // Obtiene el día de la semana (0 = Domingo, 1 = Lunes, etc.)

  // Si es domingo (0), se ajusta para que sea el día 7 (después de sábado)
  const diff = firstDay.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1);
  firstDay.setDate(diff);

  return dateFormat(firstDay);
};

export const getLastDayOfWeek = () => {
  const lastDay = new Date();
  const dayOfWeek = lastDay.getDay(); // Obtiene el día de la semana

  // Si es domingo (0), ya es el último día, sino se ajusta
  const diff = lastDay.getDate() + (dayOfWeek === 0 ? 0 : 7 - dayOfWeek);
  lastDay.setDate(diff);

  return dateFormat(lastDay);
};

export const getFirstDayOfYear = () => {
  const currentDate = new Date();
  const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1); // January 1st
  return dateFormat(firstDayOfYear);
};

export const getLastDayOfYear = () => {
  const currentDate = new Date();
  const lastDayOfYear = new Date(currentDate.getFullYear(), 11, 31); // December 31st
  return dateFormat(lastDayOfYear);
};

export const addOneYear = (date: Date) => {
  const newDate = new Date(date);
  newDate.setFullYear(newDate.getFullYear() + 1);
  return dateFormat(newDate);
};

interface DateToLongFormatProps {
  date: Date;
  weekday?: 'long' | 'short' | 'narrow';
  day?: 'numeric' | '2-digit';
  month?: 'long' | 'short' | 'narrow' | 'numeric' | '2-digit';
}

export const formatDateToLongFormat = ({
  date,
  weekday = 'long',
  day = 'numeric',
  month = 'long'
}: DateToLongFormatProps): string => {
  const options: Intl.DateTimeFormatOptions = {
    weekday,
    day,
    month
  };
  return new Intl.DateTimeFormat('es-ES', options).format(date);
};

export const getDateSevenDaysAgo = () => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 7);
  return dateFormat(currentDate);
};

export const getFirstDayOfMonth = () => {
  const currentDate = new Date();
  const firstDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  return dateFormat(firstDay);
};

export const getLastDayOfMonth = () => {
  const currentDate = new Date();
  const lastDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  return dateFormat(lastDay);
};

export const getFirstDayOfLastMonth = () => {
  const currentDate = new Date();
  const firstDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );
  return dateFormat(firstDay);
};

export const getLastDayOfLastMonth = () => {
  const currentDate = new Date();
  const lastDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    0
  );
  return dateFormat(lastDay);
};

export const convertTimeToMinutes = (time: string): number => {
  let hours: number;
  let minutes: number;

  const is12HourFormat =
    time.toLowerCase().includes('am') || time.toLowerCase().includes('pm');

  if (is12HourFormat) {
    const [timePart, modifier] = time.split(/(am|pm)/i);
    [hours, minutes] = timePart.split(':').map(Number);

    if (modifier.toLowerCase() === 'pm' && hours < 12) {
      hours += 12;
    }
    if (modifier.toLowerCase() === 'am' && hours === 12) {
      hours = 0;
    }
  } else {
    [hours, minutes] = time.split(':').map(Number);
  }

  return hours * 60 + minutes;
};

export const isValidHexColor = (input: string): boolean => {
  const hexColorPattern = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;
  return hexColorPattern.test(input);
};

// Función para generar y descargar un Excel
export const generateExcel = (data: object[], fileName: string) => {
  // Crear una hoja de cálculo (worksheet) a partir de los datos
  const ws = XLSX.utils.json_to_sheet(data);

  // Crear un libro de trabajo (workbook)
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, truncateString(fileName, 31, false));

  // Escribir y descargar el archivo Excel
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

const downloadDocument = async (fileUrl) => {
  try {
    const partesUrl = fileUrl.split('/');
    const nombreArchivo = partesUrl[partesUrl.length - 1];
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = nombreArchivo;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error al descargar el archivo:', error);
    throw new Error('Ocurrió un error al descargar el archivo');
  }
};

export const downloadFileUrl = async (
  getDocument: () => Promise<AxiosResponse<FileUrl>>
) => {
  const result = await getDocument();

  if (result) {
    if (result.data.url) {
      downloadDocument(result.data.url).catch((error) =>
        console.error('Error:', error)
      );
    }
  }
};

export const downloadFileBlob = async (
  getDocument: () => Promise<AxiosResponse<Blob>>,
  title: string,
  extension: string = 'xlsx'
) => {
  const result = await getDocument();
  if (result) {
    const url = window.URL.createObjectURL(result.data);
    const link = document.createElement('a');
    link.href = url;
    let extensionFile = extension?.replace('.', '');
    link.setAttribute('download', `${title}.${extensionFile}`);
    document.body.appendChild(link);
    link.click();
  }
};
